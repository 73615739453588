.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.wrapper-sc {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 300px;
  position: relative;
}
.wrapper-border {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px dashed rgba(39, 94, 184, 0.8);
  position: absolute;
  inset: 0px;
  transition: background 0.1s ease 0s;
  background: rgba(1, 66, 172, 0.1);
  cursor: pointer;
}
.input-file {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.chooseFile {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  /* display: none; */
}
.chooseFile-wrapper {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: -1;
  margin-bottom: 50px;
}
.upload-icon {
  position: relative;
}
.choose-file {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: 700;
  display: inline;
  font-size: 14px;
  line-height: 20px;
  color: rgb(1, 66, 172);
  margin-right: 4px;
}
.drag-drop {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  display: inline;
  font-size: 14px;
  line-height: 20px;
  color: rgb(0, 0, 0);
}
.drag-drop strong {
  margin-left: 4px;
}
.spacer-16px {
  margin-top: 16px;
}
.spacer-8px {
  margin-top: 8px;
}
.huyJew > * {
  flex: 0 0 auto;
}
.iywTnN * {
  -webkit-tap-highlight-color: transparent;
}
.iywTnN *,
.iywTnN ::before,
.iywTnN ::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
}
.size-limit {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  text-align: center;
}
.file-preview-wrapper {
  width: auto;
  margin: 0px 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  transition: all 0.2s ease 0s;
  animation: 0.2s ease 0s 1 normal forwards running hNMmMg;
  /* display: none; */
  z-index: 9999;
  display: block;
}
.file-upload {
  border-top: 1px solid rgb(1, 66, 172);
  border-right: 1px solid rgb(1, 66, 172);
  border-left: 1px solid rgb(1, 66, 172);
  border-image: initial;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  width: 100%;
}
.image- {
  max-width: 100%;
  height: 200px;
  width: auto;
  display: block;
  max-width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}
.preview-footer {
  border: 1px solid rgb(1, 66, 172);
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.file-name {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 6px 12px;
}
.file-name-wrapper {
  width: 1px;
  min-width: 1px;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.text-wrapper {
  margin: 0px;
  max-width: 100%;
  width: auto;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.extension-wrapper {
  margin: 0px;
  max-width: 100%;
  width: auto;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  text-align: center;
}
.name-spacer {
  margin-left: 8px;
}
.delete-file {
  cursor: pointer;
  appearance: none;
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  outline: none;
  padding: 8px;
}
.delete-icon {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.multipleChoice__optionKeyTip {
  display: none;
}
.css-1norszq {
  position: relative !important;
  display: flex;
  flex-direction: row-reverse !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  border-radius: 2px;
  background: rgba(1, 66, 172, 0.1) !important;
  /* box-shadow: rgba(39, 94, 184, 0.8) 0px 0px 0px 1px inset !important; */
  color: rgb(1, 66, 172) !important;
  border-color: rgb(1, 66, 172) !important;
  max-width: 100% !important;
  height: inherit;
  min-height: 32px !important;
  outline: 0px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  transition-duration: 0.1s !important;
  transition-property: background-color, color, border-color, opacity,
    box-shadow !important;
  transition-timing-function: ease-out !important;
  width: 100%;
  word-break: break-word !important;
  cursor: pointer !important;
  opacity: 1 !important;
}
.css-1norszq:hover {
  background-color: rgba(1, 66, 172, 0.3) !important;
}

.css-kpt5v9 {
  width: 20px !important;
  height: 20px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 1px !important;
  border-color: rgb(1, 66, 172, 0.6) !important;
  background-color: rgb(255, 255, 255) !important;
  margin-right: 10px;
  color: rgb(1, 66, 172) !important;
  font-size: 12px !important;
  line-height: 16px;
  font-family: sans-serif;
  font-weight: 700;
}
.css-12d8wr1 {
  height: 40px;
  font-family: inherit;
  line-height: initial;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  border: 1px solid transparent;
  margin: 0px;
  min-height: 48px;
  font-size: 23px;
  /* width: 102px; */
}
.css-12d8wr1 {
  font-size: 24px !important;
}
.css-12d8wr1 svg {
  display: none;
}
.border-class {
  border-color: #275eb8 !important;
  box-shadow: rgba(1, 66, 172, 0.8) 0px 0px 0px 1px inset !important;
}

.key-color {
  /* background-color: #275eb8 !important; */
  background-color: rgb(1, 66, 172) !important;
  color: #fff !important;
}

.kPYZOR {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 36px;
  line-height: 44px;
  color: rgb(0, 0, 0);
}
.kPYZOR a,
.kPYZOR a:visited {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}
.css-cvyfpr {
  border-bottom: 1px soild #275eb8 !important;
}
.css-107wnoj textarea,
.css-107wnoj input {
  color: rgba(39, 94, 184) !important;
  border-color: #275eb8 !important;
  box-shadow: rgba(39, 94, 184) 0px 1px !important;
}
.css-107wnoj input:focus {
  box-shadow: rgba(39, 94, 184) 0px 2px !important;
}
.css-107wnoj input::-webkit-input-placeholder {
  color: #0142ac !important;
}
.css-107wnoj input::-moz-placeholder {
  color: #0142ac !important;
}
.css-1dqkdyc p {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 36px;
  line-height: 44px;
}
.css-1183luy p {
  font-size: 20px;
  line-height: 28px;
}
.css-16xt93w p {
  display: block;
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  font-family: sans-serif;
  margin-left: 10px;
}
.css-3xlg2r {
  margin-top: 32px !important;
}
.qf-welcome-screen-block__action {
  margin-left: 85px;
}
.css-wqi77k .qf-welcome-screen-block__content-wrapper {
  padding: 0 !important;
}
.css-kyvs3s p {
  margin-left: 10px;
  font-size: 12px;
}
.css-cogszh {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #0142ac !important;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
  outline: none;
}
.css-who0lh {
  fill: #0142ac !important;
  stroke: #0142ac !important;
}
.first-block .css-12d8wr1 {
  min-height: 40px;
  width: 86px;
  font-size: 18px !important;
}
.last-block .css-12d8wr1 {
  min-height: 40px;
  width: 108px;
  font-size: 18px !important;
}
.css-12d8wr1 .renderer-core-html-parser {
  display: flex;
}
.tick {
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 4px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  margin-left: 10px;
}
.tickSelected {
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 4px;
  border-bottom: 3px solid #0142ac;
  border-right: 3px solid #0142ac;
  margin-left: 10px;
}
.selected .multipleChoice__optionLabel {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.renderer-core-field-navigation > div {
  margin: 0 !important;
  width: 36px;
}
.renderer-core-field-navigation > div:first-child {
  border-radius: 2px 0 0 2px !important;
  border-right: 0.8px solid #fff;
}
.renderer-core-field-navigation > div:last-child {
  border-radius: 0 2px 2px 0 !important;
}
.css-cogszh {
  height: 28px !important;
}
@media (max-width: 576px) {
  .renderer-components-field-wrapper
    .renderer-components-field-wrapper__content-wrapper
    .renderer-core-block-scroller {
    padding: 40px;
  }
  .css-wqi77k .qf-welcome-screen-block__content-wrapper {
    padding: 0 !important;
  }
  .qf-welcome-screen-block__action {
    margin-left: 0px;
  }
  .css-16xt93w p {
    display: none;
  }
  .css-1dqkdyc p {
    font-size: 30px;
    margin-bottom: 12px;
  }
  .css-1183luy p {
    font-size: 16px;
    line-height: 10px;
  }
  .css-biroc0::after {
    height: 100px;
  }
  .css-3xlg2r {
    position: relative;
    top: 240px;
  }
  .css-biroc0,
  .css-3xlg2r {
    margin-top: 0 !important;
  }
  .renderer-components-form-footer {
    padding: 10px 5px;
  }
  .renderer-core-field-navigation > div {
    height: 30px;
    /* width: 30px; */
  }
  .last-block .css-12d8wr1 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .kPYZOR {
    font-size: 30px;
    font-weight: unset;
    line-height: 38px;
  }
}
